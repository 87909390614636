import React, { useState } from 'react';
import Footer from '../components/Footer';
import '../App.css';
import { Button } from '../styles/SharedStyles';

const ProductsPage = () => {
  const [expandedCard, setExpandedCard] = useState(null);

  const products = [
    { 
      name: "Ordify App", 
      description: "Create your own personal AI agent for each of your family members. AI agents that will know your schedule and find ways to organize your family's busy schedule.", 
      icon: "/Ordify_white.svg", 
      link: null,
      features: [
        {
          title: "Conversational AI Assistant",
          description: `User: "Soccer practice is getting pushed by an hour this weekend, any conflicts?"\n\nOrdify: "No conflicts, but it will be tight with your lunch reservation with Mom. Would you like me to adjust the reservation?"`,
        },
        {
          title: "Intelligent Calendar Management",
          description: "Advanced AI system that proactively manages schedules, detects potential conflicts, and suggests optimal solutions for your family's busy lifestyle."
        },
        {
          title: "Achievement System",
          description: "Motivate and track progress through personalized milestones, celebrating family accomplishments with meaningful rewards and recognition."
        },
        {
          title: "AI-Powered Goal Setting",
          description: "Transform aspirations into actionable plans with intelligent time-blocking and progress tracking to help your family achieve their goals."
        },
        {
          title: "Lifestyle Optimization",
          description: "Smart activity recommendations and timely reminders to promote an active, balanced lifestyle for the whole family."
        },
        {
          title: "Digital Balance",
          description: "Intelligent screen time analytics and personalized recommendations to foster healthy digital habits while maximizing productive engagement."
        }
      ]
    },
    { 
      name: "Movewell", 
      description: "Personalized health monitoring and recommendations", 
      icon: "/movewell.png", 
      link: "https://movewell.rogerlam.co",
      features: []
    },
  ];

  const toggleFeatures = (index) => {
    setExpandedCard(expandedCard === index ? null : index);
  };

  const renderProductCards = (products) => (
    products.map((product, index) => (
      <div className={`product-card ${expandedCard === index ? 'expanded' : ''}`} key={index}>
        <div className="product-content">
          <div className="product-icon">
            <img className="product-image" src={product.icon} alt={product.name} />
          </div>
          <h2 className="product-name">{product.name}</h2>
          <p className="product-description">{product.description}</p>
          {product.features?.length > 0 ? (
            <button 
              className="features-toggle"
              onClick={() => toggleFeatures(index)}
              aria-expanded={expandedCard === index}
            >
              {expandedCard === index ? 'Hide Features' : 'View Features'}
            </button>
          ) : (
            <Button as="a" href={product.link} target="_blank" rel="noopener noreferrer" primary>
              Preview
            </Button>
          )}
        </div>
        {product.features?.length > 0 && (
          <div className="features-section">
            <div className="features-grid">
              {product.features.map((feature, idx) => (
                <div key={idx} className="feature-item">
                  <h3 className="feature-title">{feature.title}</h3>
                  <p className="feature-description">{feature.description}</p>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    ))
  );

  return (
    <div className="page-wrapper">
      <div className="products-page-wrapper">
        <div className="container">
          <h1 className="page-title">Our AI Solutions</h1>
          <div className="product-grid">
            {renderProductCards(products)}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ProductsPage;

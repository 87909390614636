const theme = {
  colors: {
    primary: '#1e3a8a',
    secondary: '#2563eb',
    background: '#ffffff',
    text: '#333333',
    gray: '#4b5563',
  },
  fonts: {
    heading: 'Montserrat, sans-serif',
    body: 'Inter, sans-serif',
  },
};

export default theme;

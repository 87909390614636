import React, { useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const Footer = () => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <FooterWrapper>
      <FooterContent>
        <FooterSection>
          <FooterTitle>Ordify AI</FooterTitle>
          <FooterText>Elevating life with AI tailored to you.</FooterText>
        </FooterSection>
        <FooterSection>
          <FooterTitle>Quick Links</FooterTitle>
          <FooterLink to="/">Home</FooterLink>
          <FooterLink to="/products">Products</FooterLink>
        </FooterSection>
        <FooterSection>
          <FooterTitle>Contact Us</FooterTitle>
          <FooterLink as="a" href="mailto:info@ordify.ai">Email: info@ordify.ai</FooterLink>
        </FooterSection>
      </FooterContent>
      <MobileFooterSection>
        <MobileMenuIcon onClick={toggleExpand}>☰</MobileMenuIcon>
        <CollapsibleContent isExpanded={isExpanded}>
          <FooterLink to="/">Home</FooterLink>
          <FooterLink to="/products">Products</FooterLink>
          <FooterLink as="a" href="mailto:info@ordify.ai">Email: info@ordify.ai</FooterLink>
        </CollapsibleContent>
      </MobileFooterSection>
      <FooterBottom>
        <FooterText>&copy; 2024 Ordify AI LLC. All rights reserved.</FooterText>
      </FooterBottom>
    </FooterWrapper>
  );
};

const FooterWrapper = styled.footer`
  background-color: #000000;
  color: #ffffff;
  padding: 2rem 1rem 1rem;
  position: relative;
`;

const FooterContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 1200px;
  margin: 0 auto;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
`;

const FooterSection = styled.div`
  margin-bottom: 2rem;
  min-width: 200px;

  @media (max-width: 768px) {
    display: none;
  }
`;

const FooterTitle = styled.h3`
  font-size: 1.2rem;
  margin-bottom: 1rem;
  color: #ffffff;
`;

const FooterText = styled.p`
  font-size: 1rem;
  color: #ffffff;
  margin-bottom: 0.5rem;
`;

const FooterLink = styled(Link)`
  display: block;
  color: #ffffff;
  text-decoration: none;
  margin-bottom: 0.5rem;
  transition: color 0.3s ease;

  &:hover {
    color: #2563eb;
  }
`;

const FooterBottom = styled.div`
  border-top: 1px solid #333333;
  margin-top: 2rem;
  padding-top: 1rem;
  text-align: center;
`;

const MobileFooterSection = styled.div`
  display: none;

  @media (max-width: 768px) {
    display: block;
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 1000;
  }
`;

const MobileMenuIcon = styled.div`
  font-size: 2rem;
  color: #ffffff;
  background-color: #1e3a8a;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
`;

const CollapsibleContent = styled.div`
  position: absolute;
  bottom: 60px;
  right: 0;
  background-color: #1e3a8a;
  padding: 1rem;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
  opacity: ${props => (props.isExpanded ? '1' : '0')};
  visibility: ${props => (props.isExpanded ? 'visible' : 'hidden')};
  transition: opacity 0.3s ease, visibility 0.3s ease;
`;

export default Footer;

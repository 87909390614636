import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Button } from '../styles/SharedStyles';

const HeroSection = () => {
  return (
    <HeroWrapper>
      <HeroContent>
        <Headline>Elevating Life with AI Tailored to You.</Headline>
        <Subheading>
          Ordify Ai LLC creates intelligent solutions for family, health, and personal growth through the power of AI.
        </Subheading>
        <CTAButtons>
          <Button as={Link} to="/products" primary>Explore AI Solutions</Button>
          <Button as="a" href="mailto:info@ordify.ai">Contact Us</Button>
        </CTAButtons>
      </HeroContent>
    </HeroWrapper>
  );
};

const HeroWrapper = styled.section`
  background-color: #ffffff;
  color: #333333;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  padding: 2rem;
`;

const HeroContent = styled.div`
  text-align: center;
  max-width: 800px;
  width: 100%;
`;

const Headline = styled.h1`
  font-size: 3.5rem;
  margin-bottom: 1rem;
  color: #1e3a8a;
  font-weight: 700;

  @media (max-width: 768px) {
    font-size: 2.5rem;
  }

  @media (max-width: 480px) {
    font-size: 2rem;
  }
`;

const Subheading = styled.p`
  font-size: 1.2rem;
  margin-bottom: 2rem;
  color: #4b5563;

  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

const CTAButtons = styled.div`
  display: flex;
  justify-content: center;
  gap: 1rem;

  @media (max-width: 480px) {
    flex-direction: column;
    align-items: center;
  }
`;

export default HeroSection;

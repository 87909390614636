import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Button } from '../styles/SharedStyles';

const CallToAction = () => {
  return (
    <CTAWrapper>
      <CTAContent>
        <CTATitle>Ready to Transform Your Life with AI?</CTATitle>
        <CTADescription>
          Join thousands of satisfied users who have improved their daily lives with Ordify AI solutions.
        </CTADescription>
        <Button as={Link} to="/products" primary>Explore AI Solutions</Button>
      </CTAContent>
    </CTAWrapper>
  );
};

const CTAWrapper = styled.section`
  background-color: #000000;
  color: #ffffff;
  padding: 4rem 0;
`;

const CTAContent = styled.div`
  max-width: 800px;
  margin: 0 auto;
  text-align: center;
  padding: 0 2rem;
`;

const CTATitle = styled.h2`
  font-size: 2.5rem;
  margin-bottom: 1rem;
  color: #ffffff;
`;

const CTADescription = styled.p`
  font-size: 1.2rem;
  margin-bottom: 2rem;
  color: #b3b3b3;
`;

export default CallToAction;
